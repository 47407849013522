<template>
  <div id="#wrapper" :class="'layout vigneron-online-cms-layout '+pageShortName+'-page'">
    <CmsComponent v-if="components.header" :key="pageShortName + '-header'" :slider="components.slider" :component="components.header"></CmsComponent>
    
    <!-- Content -->
    <div :class="cssClass">
      <CmsComponent v-for="(component, index) in components.content" :key="pageShortName + '-' + index" :component="component"></CmsComponent>
    </div>

    <Footer></Footer>
    <SideCart></SideCart>
    <ContactModal></ContactModal>
    <Cookies></Cookies>
  </div>
</template>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import Footer from '../panels/layouts/page/Footer.vue';
import SideCart from '../cms/canvas/shop/SideCart.vue';
import CmsCanvasLayout from '../mixins/CmsCanvasLayout.vue';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { utils as RouterUtils } from '@fwk-client/router/utils';
import { languagesTypes } from '@fwk-client/store/types';
import { mapGetters } from '@fwk-node-modules/vuex';

import ContactModal from '../cms/canvas/ContactModal.vue';
import Cookies from '../panels/Cookies.vue';

@Component({
  components: { Footer , SideCart, ContactModal, Cookies },
  computed: {
	...mapGetters({
			currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE,
			})
  }
})
export default class VigneronOnlineCms extends mixins(CmsCanvasLayout, GenericPage) {
  
  get pageShortName() {
    return RouterUtils.getRouteShortNameFromRoute(this.$route);
  }
  
  get cssClass() {
    return 'content '+this.pageShortName;
  }
  
  get components() {
    return this.$cms.components.getComponentsFromRoute(this.$route);
	}
  
  constructor() {
    super();
  }

  created() {
    if(process.env.CONSOLE == "LOG") { console.log("VIGNERON-ONLINE-CMS - CREATED"); }
  }

  mounted() {
    this.$cms.components.onCmsRouteMounted(this.onVigneronOnlineCmsLayoutMounted)
  }

  destroyed() {
    this.$cms.components.offCmsRouteMounted(this.onVigneronOnlineCmsLayoutMounted)
  }

  onVigneronOnlineCmsLayoutMounted() {
    this.$root.$emit('triggerScroll');
  }

}
</script>